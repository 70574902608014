import React from 'react';
import './Services.css';
import image3 from '../images/ServicesPng.png'

const Services = () => {
  return (
    <>
      <div className='containerServices' id='services'>
        <div className='contentServices'>
          <h1 className='hero-headingServices'>Services</h1>
        </div>
        <div className='imageContainer'>
          <img src={image3} alt='Services' />
        </div>
      </div>
    </>
  )
}

export default Services;
