// Footer.js
import React from 'react';
import './Footer.css'

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <footer>
      <div>B1- 1027 DLF MyPad, Opposite Hyatt Regency, Vibhuti Khand, Gomti Nagar, Lucknow - 226010</div>
      <div>© {currentYear} prithfix space. All rights reserved.</div>
    </footer>
  );
};

export default Footer;
