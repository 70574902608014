// ContactUs.js
import React, { useState } from 'react';
import './Contact.css'; // Create this CSS file for styling

const Contact = () => {

    const [user, setUser] = useState({
        name:"",
        email:"",
        profession:"",
        message:""
    })

    let name, value;
    const getUserData =(event)=>{
        name = event.target.name;
        value =  event.target.value;

        setUser({...user, [name]: value})
    }

    const postData= async (e)=>{
        e.preventDefault();


        const {name,  email ,profession,  message}= user;

        if(name &&  email && profession && message){
            const res = await fetch("https://prithfixspaceform-default-rtdb.firebaseio.com/prithfixspaceform.json",
            {
             method: "POST",
             headers: {
                "Content-Type": "application/json"
             },
             body: JSON.stringify({
                 name,
                 email,
                 profession,
                 message
             })
            })
            if(res){
             setUser({
                 name:"",
                 email:"",
                 profession:"",
                 message:""
             })
             alert("Form Submitted Successfully")
            }
            
        }else{
            alert("Please Fill All The Fields")
        }
       
    }
  return (
    <div className="contact-form" id = "contact">
      <h2>Contact Us</h2>
      <form method="POST">
      <div className="input-field">
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" value = {user.name} onChange = {getUserData} placeholder="Your name" autoComplete='off' required/>
      </div>
      <div className="input-field">
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" value = {user.email} onChange = {getUserData} placeholder="Your email"  autoComplete='off' required/>
      </div>
      <div className="input-field">
        <label htmlFor="profession">Profession</label>
        <input type="text" id="profession" name="profession" value = {user.profession} onChange = {getUserData} placeholder="Your profession"  autoComplete='off' required/>
      </div>
      <div className="input-field">
        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" value = {user.message} onChange = {getUserData} placeholder="Your message"  autoComplete='off' required></textarea>
      </div>
      <button type="submit" onClick={postData}>Submit</button>
      </form>
      
    </div>
  );
};

export default Contact;
