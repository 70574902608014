import React from 'react';

import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import DataThemes from './components/DataThemes';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Navbar/>
    <Home/>
    <About/>
    <DataThemes/>
    <Services/>
    <Contact/>
    <Footer/>
   
    
 
 </>
  );
}

export default App;