import React from 'react';
import image1 from "../images/Image1SatImage.png"
import './Home.css';
const Home = () => {
  const scrollToDataSection = () => {
    window.location.hash = '#data';
  };
   
  return (
    <>
        <div className='container' id="home">
            <div className='content'>
            <h1 className='hero-heading'>View Earth Like Never Before</h1>
            <p className='hero-para'>Space-Based Intelligence for your businesses and climate change.</p>
            <button className='data-button' onClick={scrollToDataSection}>Data Themes</button>
            </div>
            <div className='image'>
                <img
                src={image1}
                alt='Earth Observation'
                />
            </div>
            

        </div>
        
    </>
  )
};


export default Home