import React from "react";
import "./About.css";
import image2 from "../images/Image5SatImage.jpeg";
// import image3 from "../images/Image3SatImage.png";

const About = () => {
  return (
    <>
      <div className="container" id="about">
        <div className="content">
          <h1 className="hero-heading">About prithfix space</h1>
          <div className="image">
            <img src={image2} alt="Earth Observation" />
          </div>
          <p className="hero-para">
            prithfix space is a space-tech services company that uses Geospatial
            Technology like Satellite Imagery and Geographic Information
            System (GIS) to provide actionable insights helpful to solve complex
            problems across diversified sectors.
          </p>
          <p className="hero-para">
            prithfix space addresses the problem of lack of data, it bridges the
            knowledge gap by providing space-based or satellite-based
            information and data. We generate the insights and use them to
            create decision support systems that can help an individual or
            organisation make intelligent informed decisions.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
