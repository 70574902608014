import React from 'react';
import Card from './Card';
import './DataThemes.css';
import './Card.css';

const DataThemes = () => {
  const entries = [
    "Agriculture",
    "Climate Change",
    "Climate Change Adaptability",
    "Droughts",
    "Floods",
    "Insurance",
    "Planning",
    "Pollution",
    "Market Intelligence"
  ];

  return (
    
         <div className='containerData' id='data'>
    <div className='contentData'>
    <h1 className='hero-headingData'>Data Themes</h1>
    </div>

    <div className='card-container'>
    {entries.map((entry, index) => (
      <Card key={index} text={entry}  />
    ))}
  </div>
  </div>
    
   
    
  );
};

export default DataThemes;
